<template>
  <div
    class="grid-wrapper"
    @mouseleave="getEndPoint($event)"
  >
    <div
      v-if="isSelected && isActive"
      class="highlighted-area grid-row"
      :style="cssVar"
    >
      <div
        class="highlighted-cell"
        :class="{active: isSelected}"
        :style="`grid-column: ${Math.min(selectStart, selectEnd) + 1} / ${Math.max(selectStart, selectEnd) + 2}`"
      >
        <div
          class="selection-range left"
          :style="[isLeft ? { 'pointer-events': 'none' } : {}]"
          @mousedown="setMouseDown('left')"
          @mouseup="getEndPoint($event)"
        />
        <div
          class="selection-range right"
          :style="[isRight ? { 'pointer-events': 'none' } : {}]"
          @mousedown="setMouseDown('right')"
          @mouseup="getEndPoint($event)"
        />
      </div>
    </div>
    <div
      ref="grid-row"
      class="grid-row"
      :style="cssVar"
    >
      <div class="grid-cell option-info">
        <span>{{ title }}</span>
      </div>
      <div
        v-for="(data,i) in rowDataReactive"
        :id="`col-${i}-${domId}`"
        :key="i"
        :class="formatCellColor(data)"
        class="grid-cell number-cell"
        @click.prevent="getSinglePoint($event, i+1, data)"
        @mousedown="getStartPoint(i+1)"
        @mousemove="getSelectedCell($event,i+1, data)"
        @mouseup.stop="getEndPoint($event)"
      >
        <span
          class="cell-content"
        >
          <span
            v-if="(isObjectEmpty(data) && (keyInfo === 'bar_price' || keyInfo ==='bar_price_child'))"
            class="d-block currency"
          >{{ currencyCode }}</span>
          {{ formatDataKey(data, keyInfo) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import helper from '@/mixins/helper'
import calendar from '@/mixins/calendar'

export default {
  name: 'CalendarRowData',
  mixins: [helper, calendar],
  props: {
    optionId: {
      type: Number,
    },
    rowData: {
      type: Array,
    },
    keyInfo: {
      type: String,
    },
    dates: {
      type: Array,
    },
    title: {
      type: String,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    domId: {
      type: String,
    },
    height: {
      type: Number,
      default: 32,
    },
    currencyCode: {
      type: String,
      default: 'BDT',
    },
  },
  data() {
    return {
      isSelected: false,
      isRangeSelected: false,
      isMouseDown: false,
      selectStart: 0,
      selectEnd: 1,
      isLeft: false,
      isRight: false,
      chosenData: {},
    }
  },
  computed: {
    cssVar() {
      return {
        '--cols': 15,
        '--minWidth': `${(this.rowData.length * 60) + 200}px`,
        '--height': `${this.height}px`,
      }
    },
    rowDataReactive() {
      return JSON.parse(JSON.stringify(this.rowData))
    },
  },
  methods: {
    formatDataKey(data, key) {
      if (data && data[key]) {
        if (key === 'unit') {
          return this.calculateAvailableUnit(data.unit_type, data.unit, data.sold)
        }
        if (key === 'bar_price' || key === 'bar_price_child') {
          return Number(data[key]).toLocaleString()
        }
        return data[key]
      }
      return 0
    },
    calculateAvailableUnit(unitType, unit, sold) {
      if (unitType === 'IGNORE') {
        return 'N/A'
      }
      const amount = unit - sold >= 0 ? unit - sold : 0
      if (unitType === 'CAPACITY') {
        return `${amount} CAP`
      }
      if (unitType === 'QUANTITY') {
        return `${amount} Qty`
      }
      return amount
    },
    formatCellColor(data) {
      if (data && data.is_active === true) {
        return 'option-open'
      }
      return 'option-close'
    },
    setMouseDown(type) {
      this.isMouseDown = true
      this.isSelected = true
      if (type && type === 'left') {
        this.isLeft = true
      }
      if (type && type === 'right') {
        this.isRight = true
      }
      this.$emit('hidePopover')
    },
    setMouseUp() {
      this.isMouseDown = false
      this.isLeft = false
      this.isRight = false
    },
    getSinglePoint(event, cellNumber, data) {
      this.isSelected = true
      this.isRangeSelected = false
      this.selectStart = cellNumber
      this.selectEnd = cellNumber
      this.chosenData = JSON.parse(JSON.stringify(data))
      this.emitEvent(event)
    },
    getStartPoint(cellNumber) {
      if (!this.isActive) {
        this.isRangeSelected = false
        this.isSelected = false
        this.selectStart = 0
        this.selectEnd = 0
        // this.$emit('selection', this.title)
        this.$emit('selection', this.domId)
      }
      if (!this.isRangeSelected) {
        this.selectStart = cellNumber
        this.selectEnd = cellNumber
      } else {
        if (cellNumber === this.selectStart) {
          this.isLeft = true
        }
        if (cellNumber === this.selectEnd) {
          this.isRight = true
        }
      }
      this.setMouseDown()
    },
    getSelectedCell(event, cellNumber, data) {
      if (this.isMouseDown && this.isActive) {
        this.chosenData = JSON.parse(JSON.stringify(data))
        if (!this.isRangeSelected) {
          this.selectEnd = cellNumber
        } else {
          if (Math.abs(this.selectStart - this.selectEnd) === 0) {
            this.isRangeSelected = false
            this.isLeft = false
            this.isRight = false
          }
          if (this.isLeft) {
            this.selectStart = cellNumber
            return
          }
          if (this.isRight) {
            this.selectEnd = cellNumber
            return
          }
          this.selectStart = cellNumber
          this.selectEnd = cellNumber
        }
      }
    },
    getEndPoint(event) {
      if (this.isSelected && this.isMouseDown) {
        this.isRangeSelected = true
        this.setMouseUp()
        const start = Math.min(this.selectStart, this.selectEnd)
        const end = Math.max(this.selectStart, this.selectEnd)
        this.selectStart = start
        this.selectEnd = end
        this.emitEvent(event)
      }
    },
    emitEvent(event) {
      const data = {
        start: this.selectStart,
        end: this.selectEnd,
        title: this.title,
        id: this.domId,
        chosenData: this.chosenData,
        optionId: this.optionId,
      }
      this.$emit('showPopOver', event, data)
    },
    resetData() {
      if (this.isActive && this.isMouseDown) {
        if (this.isLeft) {
          this.selectStart -= 1
        }
        if (this.isRight) {
          this.selectEnd += 1
        }
        // this.$emit('selection', this.title)
        // this.isSelected = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/@core/scss/base/colors.scss';
@import '~@core/scss/base/themes/dark-layout.scss';

.grid-wrapper {
  position: relative;
  .grid-row {
    display: grid;
    grid-template-columns: 200px repeat(var(--cols), 60px);
    min-width: var(--minWidth);
    .option-info.grid-cell {
      border-left: 1px solid $border-color;
    }
    .grid-cell {
      position: relative;
      text-align: left;
      padding: 5px;
      border: 1px solid $border-color;
      border-top-width: 0;
      border-left-width: 0;
      font-size: 14px;
      background: $card-bg;
      user-select: none;
    }
    .option-info {
      position: sticky;
      left: 0;
      z-index: 3;
      //background-color: $white;
      border-right: 1px solid $border-color;
      display: flex;
      align-items: center;
    }
    .number-cell {
      text-align: right;
      .cell-content {
        font-size: 12px;
        font-weight: 500;
        .currency {
          font-size: 10px;
          font-weight: 400;
        }
      }
      &.option-open {
        background-color: #e0f5e7;
      }
      &.option-close {
        background-color: #fcbaba;
      }
    }
  }
  .highlighted-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border: 0;
    //height: 100%;
    pointer-events: none;
    z-index: 2;
    min-height: var(--height);
    height: 100%;
    .highlighted-cell {
      position: relative;
      &.active {
        border: 1px solid $primary;
        background-color: rgba(88, 76, 236, 0.15);
      }
      .selection-range {
        display: block;
        width: 8px;
        height: 105%;
        border-radius: 0;
        background: $primary;
        cursor: grabbing;
        pointer-events: auto;
        user-select: none;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        &::after {
          content: '';
          width: 1px;
          height: calc(100% - 20px);
          position: absolute;
          transform: translate(-50%, -50%);
          top: 50%;
          background: #fff;
        }
        &.left {
          left: 4px;
          &::after {
            left: 60%;
            box-shadow: -3px 0 0 #fff;
          }
        }
        &.right {
          left: calc(100% - 4px);
          &::after {
            left: 40%;
            box-shadow: 3px 0 0 #fff;
          }
        }
      }
    }
  }
}
.dark-layout {
  .grid-wrapper {
    .grid-row {
      .option-info.grid-cell {
        border-left: 1px solid $theme-dark-border-color;
      }
      .grid-cell {
        border: 1px solid $theme-dark-border-color;
        border-top-width: 0;
        border-left-width: 0;
        background: $theme-dark-card-bg;
      }
      .option-info {
        border-right: 1px solid $theme-dark-border-color;
      }
      .number-cell {
        &.option-open {
          background-color: #32fd4b52;
        }
        &.option-close {
          background-color: #fd323252;
        }
      }
    }
  }
}
</style>
