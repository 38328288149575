<template>
  <b-sidebar
    id="bulk-edit"
    ref="sidebar"
    width="45%"
    title="Bulk Edit"
    bg-variant="white"
    right
    backdrop
    no-header
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5
          class="mb-0"
        >
          Bulk Edit - {{ propOption && propOption.name ? propOption.name : '' }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <b-form
        class="p-1"
        @submit.prevent
      >
        <b-row>
          <b-col
            cols="6"
            md="6"
          >
            <label for="start-date">From</label>
            <b-form-datepicker
              id="start-date"
              v-model="startDate"
              :min="startDate"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              reset-button
              class="mb-2"
              @input="endDate=setEndDate(startDate, endDate)"
            />
          </b-col>
          <b-col
            cols="6"
            md="6"
          >
            <label for="end-date">Up to and including</label>
            <b-form-datepicker
              id="end-date"
              v-model="endDate"
              :min="startDate"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              reset-button
              class="mb-2"
              @input="startDate=setStartDate(startDate, endDate)"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Which days of the week do you want to apply changes to?"
              label-for="days"
            >
              <b-form-checkbox
                :checked="selectedWeekDays.length === 7"
                @change="toggleWeek()"
              >
                Select All
              </b-form-checkbox>
              <div class="demo-inline-spacing">
                <b-form-checkbox
                  v-for="(week, w) in weekDays"
                  :key="w"
                  v-model="selectedWeekDays"
                  :value="week.value"
                  class="mt-1"
                >
                  {{ week.label }}
                </b-form-checkbox>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="option-name mt-2">
          <p class="mb-0">
            {{ propOption.name }}
          </p>
          <!-- Quantity -->
          <b-row class="mt-1">
            <b-col
              cols="12"
              md="12"
            >
              <div
                role="tablist"
                class="collapse-icon"
              >
                <b-card
                  no-body
                >
                  <b-card-header
                    v-b-toggle.quantity
                    role="tab"
                    data-toggle="collapse"
                    class="flex-column align-items-md-start"
                  >
                    <b-card-title class="mb-1">
                      Quantity & Bar Price
                    </b-card-title>
                    <b-card-sub-title>
                      <span>
                        Update the quantity and bar price for this option
                      </span>
                    </b-card-sub-title>
                  </b-card-header>

                  <b-collapse
                    id="quantity"
                    role="tabpanel"
                  >
                    <b-card-body>
                      <!-- quanity -->
                      <b-form-group
                        label-for="quantity-title"
                        label="Quantity"
                        class="w-50"
                      >
                        <b-input-group>
                          <b-form-input
                            id="quantity-title"
                            v-model.number="quantity"
                          />
                        </b-input-group>
                      </b-form-group>
                      <!-- bar price -->
                      <b-form-group
                        label-for="bar-price-title"
                        label="Bar Price"
                        class="w-50"
                      >
                        <b-input-group :prepend="currencyCode">
                          <b-form-input
                            id="bar-price-title"
                            v-model.number="barPrice"
                          />
                        </b-input-group>
                      </b-form-group>
                      <!-- bar child price  -->
                      <b-form-group
                        label-for="bar-child-price-title"
                        label="Bar Child Price"
                        class="w-50"
                      >
                        <b-input-group :prepend="currencyCode">
                          <b-form-input
                            id="bar-child-price-title"
                            v-model.number="barChildPrice"
                          />
                        </b-input-group>
                      </b-form-group>
                      <!-- Status -->
                      <b-form-group
                        label-for="is-active"
                        class="w-100"
                      >
                        <div class="demo-inline-spacing">
                          <b-form-radio
                            v-model="isActive"
                            :value="true"
                            name="is-active"
                            class="mt-0"
                          >
                            Open
                          </b-form-radio>
                          <b-form-radio
                            v-model="isActive"
                            :value="false"
                            name="is-active"
                            class="mt-0"
                          >
                            Close
                          </b-form-radio>
                        </div>
                      </b-form-group>
                      <small class="text-muted">
                        Changes will be made to the date range:
                        {{ formatDate(startDate) }} - {{ formatDate(endDate) }}
                      </small>
                      <!-- Submit -->
                      <div class="btn-wrapper mt-2">
                        <b-button
                          variant="primary"
                          class="mr-1"
                          :disabled="(!barPrice || !quantity)|| (isLoading)"
                          @click="updateOptionBulk()"
                        >
                          Save
                          <b-spinner
                            v-if="isLoading"
                            small
                          />
                        </b-button>
                        <b-button
                          :disabled="isLoading"
                          variant="outline-danger"
                          @click="resetForm()"
                        >
                          Cancel
                        </b-button>
                      </div>
                    </b-card-body>
                  </b-collapse>
                </b-card>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import moment from 'moment'
import helper from '@/mixins/helper'
import calendar from '@/mixins/calendar'
import api from '@/libs/api'

export default {
  name: 'AvailabilityForm',
  mixins: [helper, calendar],
  props: {
    propOption: {
      type: Object,
    },
    currencyCode: {
      type: String,
      default: 'BDT',
    },
  },
  data() {
    return {
      startDate: moment(new Date()).format('YYYY-MM-DD'),
      endDate: moment(new Date()).add(14, 'days').format('YYYY-MM-DD'),
      isLoading: false,
      currentAction: '',
      weekDays: [
        { value: 0, label: 'Mon' },
        { value: 1, label: 'Tues' },
        { value: 2, label: 'Wed' },
        { value: 3, label: 'Thurs' },
        { value: 4, label: 'Fri' },
        { value: 5, label: 'Sat' },
        { value: 6, label: 'Sun' },
      ],
      selectedWeekDays: [0, 1, 2, 3, 4, 5, 6], // this stays static
      quantity: '',
      barPrice: '',
      barChildPrice: '',
      isActive: true,
    }
  },
  methods: {
    resetForm() {
      this.barPrice = ''
      this.barChildPrice = ''
      this.quantity = ''
    },
    updateOptionBulk() {
      this.isLoading = true
      const formData = {
        days: this.selectedWeekDays.toString(),
        is_active: this.isActive,
        date_from: this.startDate,
        date_to: this.endDate,
        option_id: this.propOption.id,
        unit: this.quantity,
        bar_price: this.barPrice,
      }
      if (this.barChildPrice) {
        formData.bar_price_child = this.barChildPrice
      }
      api.postData('tour/admin_services/availability_create/', true,
        formData).then(response => {
        if (response.data.status) {
          this.resetForm()
          this.$emit('getAvailabilityData')
          this.notificationMessage('success', 'CheckIcon', 'Success', 'Availability updated successfully')
        } else {
          this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
        }
      }).catch(() => {
        this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong!')
      }).finally(() => {
        this.isLoading = false
        this.currentAction = ''
      })
    },
    toggleWeek() {
      if (this.selectedWeekDays.length === 7) {
        this.selectedWeekDays = []
      } else {
        this.selectedWeekDays = this.weekDays.map(d => d.value)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#bulk-edit {
  .option-name {
    // background-color: #f5f5f5;
    border: 0;
    // padding: 10px;
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      // color: #7367f0;
      border: 0;
    }
  }
  .card-title {
    font-size: 16px;
    font-weight: 600;
    // color: #666668;
  }
  ::v-deep .v-select {
    .vs__dropdown-toggle {
      flex-basis: 100%;
      width: 100%;
    }
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
