<template>
  <div
    ref="popover"
    class="calendar-popover"
    :style="{transform : `translate3d(${optionData.popoverXPosition}px, ${optionData.popoverYPosition - height}px, 0)`}"
  >
    <div class="form-wrapper">
      <div class="option-info">
        <!-- <p class="popover-option-name">
          {{ optionData.title }}
        </p> -->
        <span class="option-type">{{ optionData.title }}</span>
      </div>
      <div class="date">
        <span>
          {{ formatDate(optionData.start_date) }}
          {{ optionData.start_date !== optionData.end_date ? `- ${formatDate(optionData.end_date)}`: '' }}
        </span>
      </div>
      <div
        class="option-status demo-inline-spacing"
      >
        <b-form-radio
          v-model="optionInfoForm.is_active"
          name="is_active"
          :value="true"
        >Open</b-form-radio>
        <b-form-radio
          v-model="optionInfoForm.is_active"
          name="is_active"
          :value="false"
        >Close</b-form-radio>
      </div>
      <div class="option-input">
        <!-- unit/availability -->
        <b-form-group
          label-cols="5"
          class="popover-input"
          label="Unit"
          label-for="unit"
        >
          <b-form-input
            id="unit"
            v-model.number="unitAvailability"
            type="number"
          />
        </b-form-group>
        <!--bar price-->
        <b-form-group
          label-cols="5"
          class="popover-input"
          label="Bar price"
          label-for="bar_price"
        >
          <b-input-group :prepend="currencyCode">
            <b-form-input
              id="bar_price"
              v-model.number="barPrice"
              type="number"
            />
          </b-input-group>
        </b-form-group>
        <!-- bar child price -->
        <b-form-group
          label-cols="5"
          label="Bar Price child"
          label-for="bar_price_child"
          class="popover-input"
        >
          <b-input-group :prepend="currencyCode">
            <b-form-input
              id="bar_price_child"
              v-model.number="barChildPrice"
              type="number"
            />
          </b-input-group>
        </b-form-group>
      </div>
    </div>
    <div class="button-wrapper">
      <b-button
        variant="danger"
        class="mr-1"
        @click="closePopover()"
      >
        Close
      </b-button>
      <b-button
        variant="primary"
        :disabled="isLoading || !optionInfoForm.bar_price || !optionInfoForm.unit"
        @click="postAvailability()"
      >
        <b-spinner
          v-if="isLoading"
          small
        />
        Save
      </b-button>
    </div>
  </div>
</template>

<script>
import helper from '@/mixins/helper'
import api from '@/libs/api'

export default {
  name: 'CalendarPopoverForm',
  mixins: [helper],
  props: {
    showPopover: {
      type: Boolean,
      default: false,
    },
    option: {
      type: Object,
    },
    currencyCode: {
      type: String,
      default: 'BDT',
    },
  },
  data() {
    return {
      isLoading: false,
      optionInfoForm: {
        bar_price: '',
        bar_price_child: '',
        is_active: true,
        unit: '',
      },
      height: 0,
    }
  },
  computed: {
    optionData() {
      return JSON.parse(JSON.stringify(this.option))
    },
    barPrice: {
      get() {
        let val = 1
        if (this.optionData.start_date === this.optionData.end_date) {
          val = this.optionData.bar_price
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.optionInfoForm.bar_price = val
        return val
      },
      set(val) {
        this.optionInfoForm.bar_price = val
      },
    },
    barChildPrice: {
      get() {
        let val = 1
        if (this.optionData.start_date === this.optionData.end_date) {
          val = this.optionData.bar_price_child
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.optionInfoForm.bar_price_child = val
        return val
      },
      set(val) {
        this.optionInfoForm.bar_price_child = val
      },
    },
    unitAvailability: {
      get() {
        let val = 1
        if (this.optionData.start_date === this.optionData.end_date) {
          val = this.optionData.unit
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.optionInfoForm.unit = val
        return val
      },
      set(val) {
        this.optionInfoForm.unit = val
      },
    },
  },
  mounted() {
    this.height = this.getPopoverHeight()
  },
  methods: {
    getPopoverHeight() {
      return this.$refs.popover.clientHeight - window.scrollY
    },
    closePopover() {
      this.isLoading = false
      this.$emit('closePopover')
    },
    postAvailability() {
      this.isLoading = true
      let formData = {}
      formData.date_from = this.option.start_date
      formData.date_to = this.option.end_date
      formData.option_id = this.option.optionId
      formData = { ...formData, ...this.optionInfoForm }
      this.callApi(formData)
    },
    callApi(data) {
      api.postData('tour/admin_services/availability_create/', true, data)
        .then(response => {
          if (response.data.status) {
            this.$emit('updateAvailabilityData', data)
            this.notificationMessage('success', 'CheckIcon', 'Success', 'Availability updated successfully')
          } else {
            this.notificationMessage('danger', 'XIcon', 'Error', response.data.error.message)
          }
        }).catch(() => {
          this.notificationMessage('danger', 'XIcon', 'Error', 'Sorry, something went wrong.')
        }).finally(() => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/@core/scss/base/colors.scss';
@import '~@core/scss/base/themes/dark-layout.scss';
.calendar-popover {
  background-color: $card-bg;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 15;
  text-shadow: none;
  background-clip: padding-box;
  border: 0;
  border-radius: .358rem;
  max-width: 300px;
  font-weight: 400;
  line-height: 1.45;
  text-decoration: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 1rem;
  word-wrap: break-word;
  will-change: transform;
  display: block;
  box-shadow: 0 0 10px 0 rgba(34, 41, 47, 15%);
  transform: translate3d(0, 0, 0);
  .form-wrapper {
    padding: 10px;
  }
  .option-info {
    color: $body-color;
    .popover-option-name {
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0;
    }
    .option-type {
      font-size: 14px;
    }
  }
  .date {
    span {
      font-size: 12px;
      color: $body-color;
    }
  }
  .option-status {
    .custom-control {
      margin-top: 10px;
    }
  }
  .option-input {
    margin-top: 20px;
    border-top: 1px solid $border-color;
    padding-top: 20px;
    .popover-input {
      label {
        flex-basis: 120px;
      }
    }
  }
  .button-wrapper {
    background-color: $table-head-bg;
    padding: 10px;
    text-align: center;
  }
}
.dark-layout {
  .calendar-popover {
    background-color: $theme-dark-card-bg;
    .option-info {
      color: $theme-dark-body-color;
    }
    .date {
      span {
        color: $theme-dark-body-color;
      }
    }
    .option-input {
      border-top: 1px solid $theme-dark-border-color;
    }
    .button-wrapper {
      background-color: $theme-dark-table-header-bg;
    }
  }
}
</style>
